/**
 * @name: 财务管理-交易流水对账接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 财务管理-交易流水对账接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IProductQuery} from "@/apis/product/info/types";

/**
 * 分页查询
 * @param param
 */
export const settlementQueryApi = (param: any) => postJ(`/admin/sale/order/settlement/query?page=${param.page}&limit=${param.limit}`, param)

/**
 * 对账
 * @param param
 */
export const settlementReconciliationApi = (param: any) => postJ(`/admin/sale/order/settlement/reconciliation`, param)

/**
 * 订单备注
 * @param param
 */
export const settlementOrderRemarksApi = (param: any) => postJ(`/admin/sale/order/settlement/orderRemarks`, param)



/**
 * 完成结算
 * @param param
 */
export const settlementBatchApi = (param: any) => postJ(`/admin/sale/order/settlement/batch`, param)


/**
 * 导出
 * @param params
 */
export const settlementApi = (params:any) => postJ("/admin/sale/order/settlement/export", params,'blob');



/**
 * 查询详情
 * @param id
 */
export const settlementDetailApi = (id: string) => get(`/admin/sale/order/settlement/detail/${id}`, )
