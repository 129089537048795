
/**
 * @name: 财务管理-交易流水对账
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 财务管理-交易流水对账
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {
  settlementApi,
  settlementBatchApi, settlementDetailApi, settlementOrderRemarksApi,
  settlementQueryApi,
  settlementReconciliationApi
} from "@/apis/financial/reconciliation";
import {deepCopy, exportFile, getPreAndNextTime} from "@/utils/common";

@Component({})
export default class financialReconciliation extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10,
    // gateIds:[],
    // payWay:[],
    // status:[]
  }
  reconDialog: boolean = false
  remarksDialog: boolean = false
  payPrice: string = '0.00'
  orderPrice: string = '0.00'
  tableDataDetail: any = []
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    labelWidth: '160px',
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        viewHide: true,
        selectable: this.rowSelectble,
      },
      {
        label: "交易流水ID",
        prop: "id",
        align: "left",
        width: 150,
        search: true
        // editHide: true,
        // addHide: true,
      },
      {
        label: "销售订单编号",
        prop: "saleOrderSn",
        align: "center",
        width: "150",
        search: true
      },
      {
        label: "档口名称",
        prop: "gateIds",
        align: "center",
        search: true,
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        type: 'select',
        hide: true,
        viewHide: true
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
      },
      {
        label: "订单金额(元)",
        prop: "orderPrice",
        align: "center",
        width: "100",
        viewHide: true,

      },
      {
        label: "支付金额(元)",
        prop: "payPrice",
        align: "center",
        width: "100"
      },
      {
        label: "抹零金额(元)",
        prop: "wipeZeroPrice",
        align: "center",
        width: "100",
        viewHide: true,

      },
      {
        label: "交易方式",
        prop: "payWay",
        align: "center",
        type: "switch",
        search: true,
        searchMultiple: true,
        dicData: [
          {
            label: "现金",
            value: 1
          },
          {
            label: "微信",
            value: 2
          },
          {
            label: "支付宝",
            value: 3
          },
          {
            label: "混合支付",
            value: 4
          },
          {
            label: "财务结算",
            value: 5
          },
          {
            label: "抖音",
            value: 6
          },
        ]
      },
      {
        label: "交易状态",
        prop: "status",
        align: 'center',
        type: "switch",
        search: true,
        searchMultiple: true,
        dicData: [
          {
            label: "未支付",
            value: 1
          },
          {
            label: "已支付",
            value: 2
          },
          {
            label: "挂账",
            value: 3
          },
          {
            label: "废单审核中",
            value: 4
          },
          {
            label: "已废弃",
            value: 5
          },
          {
            label: "退货审核中",
            value: 6
          },
          {
            label: "退货",
            value: 7
          },
          {
            label: "挂单",
            value: 8
          },
          {
            label: "已取消",
            value: 9
          },
        ]
      },
      {
        label: "经办人",
        prop: "salesPersonName",
        align: "center",
        viewHide: true
      },
      {
        label: "交易时间",
        prop: "payTime",
        width: "150",
        align: "center",
        search: true,
        type: "daterange",
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        viewHide: true
      },

      {
        label: "挂账结算确认",
        prop: "settlementNames",
        align: "center",
        overHidden: true,
        width: "100",
        viewHide: true

      },
      {
        label: "备注",
        prop: "remarks",
        align: "center",
        overHidden: true,
        viewHide: true
      },
      {
        label: "支付明细",
        prop: "orderPayVos",
        align: "center",
        hide: true,
        viewSlot: true,
      },
      {
        label: "挂账客户",
        prop: "hangingCustomerName",
        align: "center",
        hide: true,
        viewSlot: true,
      },
      {
        label: "操作",
        slot: true,
        prop: "btn",
        width: "200",
        align: "center",
        viewHide: true
      }
    ]
  }
  crudOptionDetail: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
      },
      {
        label: "销售属性",
        prop: "saleAttrName",
        align: 'center',
      },
      {
        label: "单价",
        prop: "price",
        align: 'center',
        slot: true
      },
      {
        label: "数量",
        prop: "qty",
        align: 'center',
        slot: true
      },
      {
        label: "商品金额(元)",
        prop: "productPrice",
        align: 'center',
        // slot:true
      },
      {
        label: "退货数量",
        prop: "refundQty",
        align: 'center',
        slot: true
      },
      {
        label: "退货金额(元)",
        prop: "refundPrice",
        align: 'center',
        overHidden: true
      },

    ]
  }
  selectRow: any = []
  rules = {
    payPrice: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请输入实收金额'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    remarks: [{required: true, message: '请输入备注', trigger: 'blur'},]
  }


  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  rowSelectble(row: any, index: number) {
    if (row.status == 3) {
      return true
    }
    return false
  }


  handleDetail(row: any, index: number) {
    settlementDetailApi(row.id).then((e: any) => {
      if (e) {
        this.tableDataDetail = e.saleOrderDtlVos
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  /**
   * 对账按钮
   */
  handleRecon(row: any) {
    this.modelForm = {}
    this.modelForm.orderId = row.id
    this.modelForm.saleOrderSn = row.saleOrderSn
    this.reconDialog = true
  }

  handleRemarks(row: any) {
    this.modelForm = {}
    this.modelForm.orderId = row.id
    this.modelForm.saleOrderSn = row.saleOrderSn
    this.remarksDialog = true
  }

  /**
   * 批量对账按钮
   */
  handleStatements() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认完成选中挂账单的结算?', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then((res) => {
      settlementBatchApi(this.selectIds).then(e => {
        if (e) {
          this.$message.error("操作成功")
          this.getList();
        }
      })
    })
  }

  handleStatement(row: any) {
    this.$confirm('是否确认完成该挂账单的结算?', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then((res) => {
      settlementBatchApi([row.id]).then(e => {
        console.log(e)
        if (e) {
          this.$message.success("操作成功")
          this.getList();
        } else {
          this.$message.error("不能重复操作")
        }
      })
    })
  }

  rowSave() {
    // @ts-ignore
    this.$refs['form'].validate((valid) => {
      if (valid) {
        settlementReconciliationApi({
          orderId: this.modelForm.orderId,
          payPrice: this.modelForm.payPrice
        }).then(e => {
          if (e) {
            this.$message.success('操作成功')
            this.reconDialog = false
            this.getList()
          }
        })
      }
    })
  }

  rowSaveRemarks() {
    // @ts-ignore
    this.$refs['form'].validate((valid) => {
      if (valid) {
        settlementOrderRemarksApi({
          id: this.modelForm.orderId,
          remarks: this.modelForm.remarks
        }).then(e => {
          if (e) {
            this.$message.success('操作成功')
            this.remarksDialog = false
            this.getList()
          }
        })
      }
    })
  }

  exportExcel() {
    const query: any = deepCopy(this.queryParam)
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      query.payStartTime = this.queryParam.payTime[0]
      query.payEndTime = this.queryParam.payTime[1]
    } else {
      query.payStartTime = ''
      query.payEndTime = ''
    }
    delete query.payTime
    settlementApi(query).then(e => {
      exportFile(e, '交易流水对账.xlsx')
    })
  }

  judgeHandle(payWay: any, status: any) {
    // (row.payWay !== 1 || row.payWay !== 4) && (row.status !==2 || row.status !==7)
    if ((payWay == 1 || payWay == 4) && (status == 2 || status == 7)) {
      return false
    } else {
      return true
    }
  }

  getList() {
    this.tableLoading = true
    const query: any = deepCopy(this.queryParam)
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      query.payStartTime = this.queryParam.payTime[0]
      query.payEndTime = this.queryParam.payTime[1]
    } else {
      query.payStartTime = ''
      query.payEndTime = ''
    }
    if (!query.gateIds) {
      query.gateIds = []
    }
    if (!query.status) {
      query.status = []
    }
    if (!query.payWay) {
      query.payWay = []
    }
    delete query.payTime
    settlementQueryApi(query).then(e => {
      this.tableData = e.commonPage.list;
      this.tableTotal = e.commonPage.total;
      this.payPrice = e.payPrice
      this.orderPrice = e.orderPrice
      this.tableLoading = false
    })
  }

  created() {
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.payTime = dateRange;
    this.getList()
  }
}
